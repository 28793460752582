<template>

    <router-link class="rounded-lg flex flex-col relative bg-filters overflow-hidden" :to="{ name:'pipelineMobile' }">

        <loader :loading="loading" />

        <div class="h-8 p-2 flex flex-row justify-between items-center">
            <span class="font-semibold text-xs" style="color: #13F2E3">Opportunity Management</span>
            <i v-if="sales.won_with_next_to_win_objective  >= 100&&isNotNaAndTbd(sales.won_with_next_to_win_objective )" class="mdi mdi-arrow-top-right text-aux text-xs"></i>
            <i v-else-if="isNotNaAndTbd(sales.won_with_next_to_win_objective)" class="mdi mdi-arrow-bottom-left text-danger text-aux text-xs"></i>
        </div>

        <div class="flex-1 min-h-0 flex flex-col justify-center items-center relative">

            <!-- <ChartDoubleGaugeHome :data="[sales.won_with_next_to_win_objective, sales.won_objetive]" :limit="sales.won_with_next_to_win_with_estimated_objective"/> -->

            <chart-double-gauge :data="[[sales.won_objective,sales.won_with_next_to_win_objective],sales.won_with_next_to_win_with_estimated_objective]"
                :limit="sales.won_with_next_to_win_with_estimated_objective"
            />

            <div class="absolute h-16 w-16 rounded-full flex flex-col justify-center items-center">

                <span class="text-white font-semibold text-xs">
                    {{ sales.won_with_next_to_win_amount | reduceBigNumbers(2) }} €
                </span>

                <div class="text-3xs font-semibold"
                    :class="successOrDangerClass(sales.won_with_next_to_win_objective,100)">
                    {{ sales.won_with_next_to_win_objective  | numberFormat(2) }}<span v-if="isNotNaAndTbd(sales.won_with_next_to_win_objective)">%</span>
                </div>

            </div>


        </div>

    </router-link>

</template>

<script>

    const ChartDoubleGauge = () => import('@/charts/ChartDoubleGaugeSales.vue');
    import { state } from '@/store';

    export default {
        name: "PipelineHomeMobile",
        components: {
            ChartDoubleGauge
        },
        data() {
            return {
                loading: false,
                sales: {}
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
        },
        watch: {
            params() { this.load() }
        },
        methods: {
            load() {
                this.loading = true;
                if(this.params) {
                    this.axios.get('pipeline/sales', {params: this.params}).then(response => {
                        this.sales = response.data.data
                        this.loading = false
                    });
                }
            },
            goto(route) { this.$router.push( { name: route } )}
        },
        mounted(){
            this.load();
        }
    }
</script>